.cards {

    margin: 30px auto auto;
    padding: 10px;
    background-color: rgba(255,255,255, .3);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-wrap: wrap;
}

.mgp-cards {
    display: flex;
    flex-wrap: wrap;
}

.square {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .cards {
        width: 350px;
    }
    .mgp-cards {
        width: 350px;
    }
    .square {
        height: 50px;
        width: 50px;
        padding: 4px;
        margin: 6px;
    }
    .square p {
        font-size: 8px;
    }
}

@media screen and (min-width: 1000px) {
    .cards {
        width: 700px;
    }
    .mgp-cards {
        width: 700px;
    }
    .square {
        height: 100px;
        width: 100px;
        padding: 8px;
        margin: 12px;
    }
    .square p {
        font-size: 16px;
    }
}
