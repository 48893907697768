html,body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #a1c9c9;
  background-image: linear-gradient(to right, #a1c9c9 , #f6f7f1);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:focus { outline:0 !important; }

* {
  font-family: "Nunito";
}

.app {
  text-align: center;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;;
}

.content {
  margin-top: 80px;
  display: flex;
  vertical-align: middle;
  min-height: 80vh;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.copyright {
  margin-top: 30px;
  margin-bottom: 30px;
}
