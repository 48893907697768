.navigation-item {
    cursor: pointer;
    font-size: 6px;
    margin: auto auto auto 10px;
    display: inline;
    padding: 0;
    color: black;
}

.header {
    text-align: left;
    height: 100px;
    color: white;
    font-size: 18px;
    width: 100%;
}

.header-content {
    height: 100px;
    margin: auto;
    display: flex;
    text-align: left;
    justify-content: space-between;
}

@media screen and (min-width: 1000px) {
    .header-content  {
        margin-left: 40px;
        width: calc(100% - 80px);
        flex-direction: row;
    }
    .name {
        font-size: 12px;
    }
    .role {
        font-size: 8px;
    }
}

@media screen and (max-width: 1000px) {
    .header-content  {
        max-width: 800px;
        flex-direction: column;
    }
    .navigation {
        margin-top: -20px;
    }
    .name {
        font-size: 8px;
    }
    .role {
        font-size: 6px;
    }
    .navigation-item {
        margin-top: 10px;
    }
    .navigation-item.one {
        margin-left: 0;
    }
    .navigation-item.three {
        margin-right: 0;
    }
}

.name-role {
    height: 100px;
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-left: 0;
}

.name {
    margin: auto;
    display: inline;
    padding: 0;
    font-weight: bold;
    color: black;
}

.role {
    margin: auto auto auto 12px;
    display: inline;
    padding: 0;
    color: black;
}

.navigation {
    height: 100px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.header-content a {
    color: inherit;
    text-decoration: none;
}
